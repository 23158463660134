<template>
  <div class="content">{{ posid }}</div>
</template>

<script>
export default {
  name: 'Position',
  components: {},
  computed: {
    posid() {
      return this.$route.params.posid;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
